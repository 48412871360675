<template>
    <div>
        <v-text-field
            v-model="search"
            label="Поиск"
            height="44"
            hide-details
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            class="mb-5"
            @change="getList"
        >
        </v-text-field>
        <v-data-table
            :headers="headers"
            :items="items.results"
            :server-items-length="items.count"
            disable-sort
            :options.sync="options"
            @click:row="goPage"
        >
            <template v-slot:[`item.auto_accept`]="{ item }">
                {{ item.auto_accept ? 'Есть' : 'Нет' }}
                <!-- <v-btn small :color="item.auto_accept ? 'success' : 'error'">{{ item.auto_accept ? 'Есть' : 'Нет' }}</v-btn> -->
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <vue-table-actions :item='item' @onEdit='onEdit(item)' @onDelete='onDelete(item)'/>
            </template>
        </v-data-table>

        <!-- Agent Info Dialog -->
        <v-dialog v-model="dialogAgentInfo" max-width="700px">
          <v-card>
            <div class="pt-10 pb-10 pr-16 pl-16">
              <v-row class="mb-5">
                <v-col cols="12" md="6">
                  <h2 class="primary--text pt-1" v-if="selectedAgent.tour_agent" v-text="selectedAgent.tour_agent.name"></h2>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    @click="$router.push(`/myTourAgents/${selectedAgent.id}`)"
                    large
                    class="w-full"
                    color="warning"
                    outlined
                    >Сотрудники</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <p class="grey--text lighten-4">Название</p>
                  <p class="bordered" v-if="selectedAgent.tour_agent" v-text="selectedAgent.tour_agent.name"></p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="grey--text lighten-4">Юр адрес</p>
                  <p class="bordered" v-if="selectedAgent.tour_agent" v-text="selectedAgent.tour_agent.legal_address"></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <p class="grey--text lighten-4">Факт адрес</p>
                  <p class="bordered" v-if="selectedAgent.tour_agent" v-text="selectedAgent.tour_agent.actual_address"></p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="grey--text lighten-4">ФИО директора</p>
                  <p class="bordered" v-if="selectedAgent.tour_agent" v-text="selectedAgent.tour_agent.manager_fullname"></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <p class="grey--text lighten-4">Банковские реквезиты</p>
                  <p class="bordered" v-if="selectedAgent.tour_agent" v-text="selectedAgent.tour_agent.bank_details_account"></p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="grey--text lighten-4">Контактное лицо</p>
                  <p class="bordered" v-if="selectedAgent.tour_agent" v-text="selectedAgent.tour_agent.contact_person"></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <p class="grey--text lighten-4">Телефон</p>
                  <p class="bordered" v-if="selectedAgent.tour_agent" v-text="selectedAgent.tour_agent.phone_number"></p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="grey--text lighten-4">Email</p>
                  <p class="bordered" v-if="selectedAgent.tour_agent" v-text="selectedAgent.tour_agent.email"></p>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-dialog>
        <!-- END Agent Info Dialog -->
        <!-- Edit Budget Dialog -->
        <v-dialog v-model="dialogEdit" max-width="400px">
          <v-card>
            <div class="pt-10 pb-10 pr-16 pl-16">
              <v-row class="mb-5">
                <v-col cols="12" md="12">
                  <h2 class="primary--text pt-1 ml-n3 mb-3">Изменить данные</h2>
                </v-col>
              </v-row>
              <v-row class="mb-3">
                <v-text-field
                  type="number"
                  min="0"
                  v-model="selectedAgent.balance"
                  label="Баланс"
                  outlined
                ></v-text-field>
                <v-text-field
                  type="number"
                  min="0"
                  v-model="selectedAgent.limit"
                  label="Лимит"
                  outlined
                ></v-text-field>
                <v-checkbox class="my-0" v-model="selectedAgent.auto_accept" label="Авто подтверждение"></v-checkbox>
              </v-row>
              <v-row>
                <v-btn
                  @click="changeBalance"
                  color="primary"
                  class="w-full"
                  large
                  >Сохранить</v-btn
                >
              </v-row>
            </div>
          </v-card>
        </v-dialog>
        <!-- END Edit Budget Dialog -->
        <!-- Delete Agent Dialog -->
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <div class="pt-10 pb-10 pr-16 pl-16">
              <v-row class="mb-5">
                <v-col cols="12" md="12">
                  <h2 class="primary--text pt-1 ml-n3 mb-3">Удалить турагента</h2>
                </v-col>
              </v-row>
              <v-row class="mb-5">
                <p>Вы уверены что хотите удалить турагента?</p>
              </v-row>
              <v-row>
                <v-btn @click="deleteAgent" color="error" class="w-full mb-3" large
                  >Удалить</v-btn
                >
                <v-btn
                  @click="
                    {
                      {
                        dialogDelete = false;
                      }
                    }
                  "
                  color="primary"
                  class="w-full"
                  outlined
                  large
                  >Отменить</v-btn
                >
              </v-row>
            </div>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import vueTableActions from '@/components/ui/vueTableActions.vue'
import pagination from "@/utils/pagination"
import {tourAgentService} from '@/services/tourAgent.service.js'
export default {
    mixins: [pagination],
    components: {
        vueTableActions
    },
    data: () => ({
        dialogAgentInfo: false, 
        selectedAgent: {}, 
        dialogEdit: false, 
        dialogDelete: false,
        search: null,
        items: {},
        headers: [
            { text: 'Турагент', value: 'tour_agent.name' },
            // { text: 'Статус', value: 'number' },
            { text: 'Баланс', value: 'balance' },
            { text: 'Лимит', value: 'limit' },
            { text: 'Автоподтверждение', value: 'auto_accept' },
            { text: '', value: 'actions' },
        ]
    }),
    methods: {
        async getList() {
            this.loading = true
            let params = {
                page: this.options.page, 
                size: this.options.itemsPerPage,
                search: this.search
            }
            this.items = await tourAgentService.getMyAgents(params)
            this.loading = false
        },
        onEdit(value) {
            this.selectedAgent = this.$copy(value)
            this.dialogEdit = true;
        },
        async changeBalance() {
            try {
                await tourAgentService.setMyAgent(this.selectedAgent.id, {
                    balance: this.selectedAgent.balance,
                    limit: this.selectedAgent.limit,
                    auto_accept: this.selectedAgent.auto_accept
                });
                this.dialogEdit = false;
                this.getList();
            } catch (error) {
                console.log(error);
            }
        },
        onDelete(item) {
            this.selectedAgent = this.$copy(item)
            this.dialogDelete = true
        },
        goPage(item) {
            this.selectedAgent = this.$copy(item)
            this.dialogAgentInfo = true
        },
        async deleteAgent(){
            await tourAgentService.deleteMyAgent(this.selectedAgent.id)
            this.getList()
            this.dialogDelete = false
        }
    }
}
</script>

<style lang="scss" scoped>
.bordered {
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  width: 100%;
}
.w-full {
  width: 100%;
}
</style>